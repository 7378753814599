import { Box, Button, Container, Grid2, Link, Stack, Typography, Chip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import leafImage from '../assets/leaf.png'
import procesorImage from '../assets/processor.png'
import diskImage from '../assets/disk.png'
import ramImage from '../assets/ram.png'
import warrentyImage from '../assets/warrenty.png'
import CallIcon from '@mui/icons-material/Call';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import CircularProgress from '@mui/material/CircularProgress';
import Slider from "react-slick";
import AdUnitsIcon from '@mui/icons-material/AdUnits';




const tittleText = '¡Tu Equipo Ideal!'
export default function TenthView({ formData }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,  // Mostrar dos productos por vez
    slidesToScroll: 1
  };


  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  const getProduct = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      const dataFormat = {
        ...data[0],
        type: formData.type
      }
      return dataFormat
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Si productPromise es una promesa, esperamos su resolución
    getProduct().then((result) => {
      if (result) {
        setProduct(result);
      } else {
        console.error("No se encontró el producto.");
      }
      setLoading(false); // Cambia a false cuando se resuelve la promesa
    }).catch((error) => {
      console.error("Error cargando el producto:", error);
      setLoading(false); // También en caso de error dejamos de cargar
    });
  }, []);

  if (
    loading) {
    return (
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '70vh' // Cambia a 100vh para ocupar toda la altura de la ventana
        }}
      >
        <CircularProgress sx={{ mb: 2 }} />
        <Typography ml={2}>Buscando tu equipo ideal...</Typography>
      </Container>
    );
  }

  return (
    <Container
      sx={{
        flex: 1,
        px: { xs: 2, md: 30 },
      }}

    >
      <Stack sx={{
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        display: 'flex',
        gap: 2
      }}
        mx={3}
        display={'flex'}
        textAlign={'center'}
        spacing={1}
      >
        <Typography variant='h1' color='primary' >{tittleText} </Typography>
        <Box width={'100%'}>
          {product.image_url ?
            (
              <Box
                height={200}
                width={'100%'}
                bgcolor='backGray.main'
                borderRadius={'20px'}
              >
                <img
                  src={product.image_url}
                  alt='Green Computer'
                  width={'auto'}
                  height={'100%'}
                  style={{ borderRadius: '20px' }} // Aplica borderRadius aquí

                />
              </Box>
            ) : (
              <Box
                height={200}
                width={'100%'}
                bgcolor='backGray.main'
                borderRadius={'20px'}
              >
                {product.type === "desktop" ?
                  <AdUnitsIcon
                    sx={{ fontSize: 200, color: '#39628e24' }}
                  />
                  :
                  <LaptopMacIcon

                    sx={{ fontSize: 200, color: '#39628e24' }}
                  />}
              </Box>
            )

          }
        </Box>
        <Box display={'flex'} width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
          <Stack textAlign={'left'}>
            <Typography variant='h3'>{product.product_name}</Typography>
            <Typography variant='h2'>${product.price}</Typography>

          </Stack>

          <Typography textAlign={'left'} variant='h4'>5% OFF Transferencia<br /> 10% OFF Efectivo</Typography>

        </Box>
        <Box textAlign={'left'} display={'flex'} p={1} borderRadius={4} bgcolor={'#01B73814'}>
          <Typography variant='body1'>
            Con esta elección, evitas que {product.carbon_print} kg de CO2,
            generados en la fabricación de nuevos equipos, sean liberados al planeta.
          </Typography>
          <img src={leafImage} alt='Carbon Footprint' width={50} height={50} />

        </Box>
        <Box textAlign={'left'} width={'100%'}>
          <Typography mb={1} variant='body1' fontWeight={600}>Características</Typography>

          <Grid2 container spacing={1} width={'100%'} >
            <Grid2 size={6} display={'flex'} alignItems={'center'} justifyContent={'start'}>
              <img src={procesorImage} alt='Processor' width={15} height={15} />
              <Typography ml={1} variant='body1'>Procesador {product.processor}</Typography>

            </Grid2>
            <Grid2 size={6} display={'flex'} alignItems={'center'} justifyContent={'start'}>
              <img src={ramImage} alt='Ram' width={15} height={15} />
              <Typography ml={1} variant='body1'>Ram {product.ram}</Typography>
            </Grid2>
            <Grid2 size={6} display={'flex'} alignItems={'center'} justifyContent={'start'}>
              <img src={diskImage} alt='Disk' width={15} height={15} />
              <Typography ml={1} variant='body1'>Disco {product.disk}</Typography>
            </Grid2>
            <Grid2 size={6} display={'flex'} alignItems={'center'} justifyContent={'start'}>
              <img src={warrentyImage} alt='Warranty' width={15} height={15} />
              <Typography ml={1} variant='body1'>{product.warranty}</Typography>
            </Grid2>


          </Grid2>
        </Box>
        <Stack pb={1} pt={2} spacing={2} direction={'row'} width={'100%'}>
          {<Button fullWidth
            sx={{ p: 2, borderRadius: '10px' }}
            startIcon={<ShoppingCartCheckoutIcon />}
            color='warning'
            variant="contained"
            onClick={() => window.open(product.product_url, '_blank')}
          >Comprar
          </Button>}
          <Button fullWidth
            sx={{ p: 2, borderRadius: '10px' }}
            color='success'
            startIcon={<CallIcon />}
            variant="contained"
            onClick={() => window.open(`https://api.whatsapp.com/send?phone=5491122534035&text=%C2%A1Hola!%20Estoy%20interesado%20en%20la: ${product.product_name} `, '_blank')}
          > Whatsapp

          </Button>
        </Stack>
        <Box textAlign={'left'} width={'100%'}>
          <Typography mb={1} variant='body1' fontWeight={600}>Otras Sugerencias</Typography>
        </Box>

        <Box width="100%" maxWidth="1200px" pb={5} margin="auto"> {/* Ajuste del contenedor */}
          <Slider id='slider' {...settings}
          >
            {product.suggested_products.map((suggestedProduct, index) => (
              <Link
                href={suggestedProduct.product_url}
                key={suggestedProduct.product_name}
                sx={{ textDecoration: 'none' }}
                target="_blank"
                rel="noopener"

              >

                <Box key={suggestedProduct.product_name} padding={2} fullWidth >
                  {suggestedProduct.image_url ?
                    <Box position={"relative"} width="100%">
                      {index === 0 && product.hasPromo ? (
                        <Chip
                          sx={{
                            position: "absolute",
                            zIndex: 2,
                            backgroundColor: "#ffe600",
                            bottom: 10,
                            right: 10,
                          }}
                          size='small'
                          label="Promo"
                        />
                      ) : null}

                      <Box
                        component="img"
                        src={suggestedProduct.image_url}
                        alt="Suggested Product Image"
                        sx={{
                          width: '100%',
                          height: {
                            xs: 170,
                            md: 220
                          },
                          objectFit: 'cover', // Mantiene la proporción
                          borderRadius: '20px' // Aplica borderRadius
                        }}
                      />

                    </Box>
                    :
                    (
                      <Box
                        position={"relative"}
                        height={{ xs: 170, md: 220 }}
                        width={'100%'}
                        bgcolor='backGray.main'
                        borderRadius={'20px'}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        {index === 0 ? (
                          <Chip
                            sx={{
                              position: "absolute",
                              zIndex: 2,
                              backgroundColor: "#ffe600",
                              bottom: 10,
                              right: 10,
                            }}
                            size='small'
                            label="Promo"
                          />
                        ) : null}

                        {product.type === "desktop" ?
                          <AdUnitsIcon
                            sx={{ fontSize: { xs: 100, md: 140 }, color: '#39628e24' }}
                          /> :
                          <LaptopMacIcon
                            sx={{ fontSize: { xs: 100, md: 140 }, color: '#39628e24' }}
                          />}
                      </Box>
                    )}
                  <Box textAlign={'left'}>
                    <Typography
                      variant='body1'
                      color='info'
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {suggestedProduct.product_name}
                    </Typography>
                    <Typography variant='body2' color='info' >
                      ${suggestedProduct.price}
                    </Typography>
                  </Box>
                </Box>
              </Link>
            ))}
          </Slider>
        </Box>


      </Stack>

    </Container>

  )
}
