import { Button, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import bienvenidaImg from '../assets/bienvenida.png'
const tittleText = '¡En Green Computer te ayudamos a encontrar tu equipo ideal en minutos!'
//const buttonText = 'Continuar'
const descText = 'Con nuestro formulario simple y personalizado, obtendrás recomendaciones hechas a medida según tus necesidades y presupuesto. ¡Descubre tu equipo perfecto ahora!'

export default function InitialView({ nextStep }) {
    return (
        <Container
            sx={{ flex: 1,
                px: { xs: 2, md: 30 },
             }}
            
        >

            <Stack sx={{ height: '85vh', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column', display: 'flex', gap: 2 }}
                mx={3} display={'flex'}
            >
                <Stack alignItems={'center'} spacing={2} textAlign={'center'}>
                    <img
                        src={bienvenidaImg}
                        alt='Green Computer'
                        width={160}
                        height={'auto'}
                    />

                    <Typography variant='h1' >{tittleText} </Typography>
                    <Typography variant='body1' > {descText}  </Typography>
                </Stack>


                <Button fullWidth
                    sx={{ p: 2, borderRadius: '10px' }}
                    variant="contained" onClick={() => nextStep()}>Continuar
                </Button>
            </Stack>

        </Container>
    )
}
