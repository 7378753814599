import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import image from '../assets/cual es.png'
import { useState } from 'react';

const tittleText = '¿Cuál es mi equipo ideal?'
const desc = 'Ya casi terminamos!'
const descData = 'Ingresa tu mail y te enviaremos la información.'
export default function NinethView({ nextStep, handleUpdate }) {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleTelChange = (event) => {
    const input =event.target.value;

    const validInput = input.replace(/[^0-9]/g, "");

    setTel(validInput);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (name !== '' && email !== '') {
      handleUpdate("lead_name", name);
      handleUpdate("lead_phone", tel);
      nextStep("lead_email", email);
    }
  };

  return (
    <Container
      sx={{ flex: 1, px: { xs: 2, md: 30 } }}
    >
      <Stack sx={{ height: '85vh', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', display: 'flex', gap: 2 }}
        mx={3} mt={4} display={'flex'}
        textAlign={'center'}
        spacing={4}
      >
        <img
          src={image}
          alt='Green Computer'
          width={160}
          height={'auto'}
        />
        <Box>
          <Typography variant='h1' >{tittleText} </Typography>
          <Typography mt={1} variant='body1' >{desc} <br /> {descData} </Typography>
        </Box>
        <Box width={'100%'}>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              id="outlined-basic"
              placeholder='Tu nombre'
              type='text'
              onChange={handleNameChange}
              sx={{
                mb: 1,
                '& .MuiInputBase-root': {
                  borderRadius: '10px',
                  backgroundColor: '#F6F6F6',
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                },
                '& .MuiInputBase-input': {
                  padding: '10px 14px',
                  borderBottom: 'none',
                },
              }}
            />
            <TextField
              fullWidth
              onChange={handleEmailChange}
           
              id="outlined-basic"
              placeholder='correo@gmail.com'
              type='email'
              sx={{
                mb: 1,
                '& .MuiInputBase-root': {
                  borderRadius: '10px',
                  backgroundColor: '#F6F6F6',
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                },
                '& .MuiInputBase-input': {
                  padding: '10px 14px',
                  borderBottom: 'none',
                },
              }}
            />
            <TextField
              fullWidth
              id="outlined-basic" 
              placeholder='WhatsApp: 11 5565 4455'
              type='tel'
              value={tel}

              onChange={handleTelChange}
              sx={{
                mb: 1,
                '& .MuiInputBase-root': {
                  borderRadius: '10px',
                  backgroundColor: '#F6F6F6',
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                },
                '& .MuiInputBase-input': {
                  padding: '10px 14px',
                  borderBottom: 'none',
                },
              }}
            />
            
            <Button
              fullWidth
              sx={{ mt:2, p: 2, borderRadius: '10px' }}
              variant="contained"
              type='submit'
              disabled={name === '' || email === ''}
            >
              Continuar
            </Button>
          </form>
        </Box>
      </Stack>
    </Container>
  );
}
